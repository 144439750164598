import React from 'react';
import {Header} from "../header/header";
import {Outlet} from "react-router-dom";
import {Footer} from "../footer/footer";
import './layout.scss';

export const Layout = () => {
    return (
        <>
            <Header/>
            <main>
                <Outlet/>
            </main>
        </>
    );
};

export default Layout;