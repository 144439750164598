import React, {useEffect, useRef, useState} from 'react';
import style from './logs.module.scss';
import {IoIosSearch} from "react-icons/io";
import {useGetLogsQuery} from "../../services/logs";
import {IParamsLogs} from "../../types/params";
import Log from "../../components/logs/log/log";
import {GrLinkNext, GrLinkPrevious} from "react-icons/gr";

const Logs = () => {
    const [searchInput, setSearchInput] = useState('');
    const [params, setParams] = useState<IParamsLogs>({
        page: 1,
        log_type: [],
        fio: '',
    });
    const {data: logs} = useGetLogsQuery(params);
    const pageRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const debounce = setTimeout(() => {
            setParams({...params, fio: searchInput})
        }, 300);

        return () => clearTimeout(debounce);
    }, [searchInput]);

    const scrollToTop = () => {
        if (pageRef.current) {
            pageRef.current.scrollTop = 0;
        }
    };

    const filterLogs = (str: string) => {
        if (params.log_type?.includes(str)) {
            setParams({...params, log_type: params.log_type?.filter(item => item !== str)})
        } else {
            setParams({...params, log_type: [...params.log_type!, str]})
        }
    }

    const filterActive = (str: string) => {
        if (params.log_type?.includes(str)) {
            return style['activate']
        } else {
           return '';
        }
    }


    return (
        <div className={style.logs}>
            <div className={style.header}>
                <h2>Логи</h2>
            </div>
            <div className={style.options}>
                <div className={style.options__left}>
                    <div className={style.filters}>

                        <button className={style.filters__edit + ' ' + style['filter-btn'] + ' ' + filterActive('form_edit')}
                                onClick={() => filterLogs('form_edit')}
                        >
                            Редактирование Анкеты
                        </button>

                        <button className={style.filters__edit + ' ' + style['filter-btn'] + ' ' + filterActive('user_edit')}
                                onClick={() => filterLogs('user_edit')}
                        >Редактирование пользователя
                        </button>

                        <button className={style.filters__create + ' ' + style['filter-btn'] + ' ' + filterActive('user_create')}
                                onClick={() => filterLogs('user_create')}>Создание</button>

                        <button className={style.filters__enter + ' ' + style['filter-btn'] + ' ' + filterActive('auth')}
                                onClick={() => filterLogs('auth')}>Вход</button>
                    </div>
                </div>
                <div className={style.options__right}>
                    <label data-cy='logs_search' className={style.search}>
                        <IoIosSearch size={24}/>
                        <input
                            type="search"
                            className={style.search__input}
                            placeholder="Поиск пользователей"
                            value={searchInput}
                            onChange={(e) => setSearchInput(e.target.value)}
                        />
                    </label>
                </div>
            </div>
            <div className={style.logs__container} ref={pageRef}>
                {
                    logs?.length
                        ?
                        logs?.map(item =>
                            <Log log={item} key={item.event_time}/>
                        )
                        :
                        <h2>Нет Данных</h2>
                }
                <div className={style.pages}>
                    {
                        params.page > 1 &&
                        <div className={style.pages__item} onClick={() => {
                            setParams({...params, page: params.page - 1});
                            scrollToTop();
                        }}>
                            <GrLinkPrevious/>
                            <span>Предыдущая страница</span>
                        </div>
                    }
                    {
                        logs?.length === 100 &&
                        <div className={style.pages__item} onClick={() => {
                            setParams({...params, page: params.page + 1});
                            scrollToTop();
                        }}>
                            <span>Следующая страница</span>
                            <GrLinkNext/>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default Logs;