import React, {useRef, useState} from 'react';
import style from './question.module.scss';
import {useDeleteQuestionMutation, useUpdateQuestionMutation} from "../../services/form";

interface Props {
    text: string,
    id: number,
    questionNumber: number
}

const Question = ({id, text, questionNumber}: Props) => {
    const [readonly, setReadonly] = useState(true);
    const textRef = useRef<HTMLTextAreaElement>(null);
    const [questionText, setQuestionText] = useState(text);
    const [updateQuestion] = useUpdateQuestionMutation();
    const [deleteQuestion] = useDeleteQuestionMutation();

    const edit = () => {
        setReadonly(false);
        textRef.current!.focus();
        textRef.current!.selectionStart = questionText.length;
    }

    const updateQuestionClick = async () => {
        try {
            await updateQuestion({id, text: questionText});
            setReadonly(true);
        } catch (e) {
            console.log(e);
        }
    }

    const deleteQuestionClick = async () => {
        try {
          await  deleteQuestion(id);
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <div className={style.question}>
            <div className={style.header}>
                <span>Вопрос {questionNumber + 1}</span>
                {
                    readonly ?
                        <button data-cy="btn-edit" className={style.btn} onClick={edit}>Редактировать</button>
                        :
                        <div className={style['btn-wrapper']}>
                            <button className={style.btn + ' ' + style.change} onClick={updateQuestionClick}>Изменить</button>
                            <button className={style.btn + ' ' + style.delete} onClick={deleteQuestionClick}>Удалить</button>
                        </div>

                }

            </div>
            <textarea data-cy="textarea-question" ref={textRef} className={style.text} value={questionText} readOnly={readonly}
                      onChange={(e) => setQuestionText(e.target.value)}>
            </textarea>
        </div>
    );
};

export default Question;