import React, {useRef} from 'react';
import style from './short-result.module.scss'
import {useGetShortResultQuery} from "../../../services/resultsService";
import {asBlob} from "html-docx-js-typescript";
import {saveAs} from "file-saver";
import {BIG_FIVE_TEST_DESCRIPTION} from "../../../data/BIG_FIVE_TEST_DESCRIPTION";
import {GERCHIKOV_TEST_DESCRIPTION} from "../../../data/GERCHIKOV_TEST_DESCRIPTION";

interface Props {
    id: number,
}

const ShortResult = ({id}: Props) => {
    const {data, isSuccess} = useGetShortResultQuery(id);
    const resRef = useRef<HTMLDivElement>(null);
    const gen = () => {
        const html = resRef.current!.innerHTML
        asBlob(html).then(dataload => {
            // @ts-ignore
            saveAs(dataload, `Краткие результаты - ${data.user_name}.docx`)
        })
    }

    return (
        <>
            {
                isSuccess &&
                <div className={style.result}>
                    <div ref={resRef} className={style.body}>
                        <div className={style.header}>
                            <h2>Краткие результаты - {data.user_name}</h2>
                            <span>Общее время: {data.total_completion_time}</span>
                        </div>
                        <div className={style.header}>
                            <h2>Тест Адизеса</h2>
                            <span>Потратил времени {data.adizes_test_result.completion_time}</span>
                        </div>
                        <span>Код: {data.adizes_test_result.key}</span>
                        <div className={style.keys}>
                            {Object.values(data.adizes_test_result.keys).map(item =>
                                <div className={style.keys__item}>
                                        <div className={style.underline}>
                                            <u>
                                                {item.name + ': ' + item.score + ' ' + item.textScore}
                                            </u></div>
                                        <div>{item.description}</div>
                                </div>
                            )}
                        </div>
                        <div className={style.header}>
                            <h2>Пятифакторный тест</h2>
                            <span>Потратил времени {data.big_five_test_result.completion_time}</span>
                        </div>
                        <div className={style.factors}>
                            {
                                data.big_five_test_result.factors.map((factor, index) =>
                                    <div key={index} className={style.factor} style={{borderBottom: '1px solid #ccd0d3'}}>
                                        <span><u>{factor.name} {factor.score}/{factor.maxScore}</u></span>
                                        {
                                            factor.subfactors.map(sub =>
                                                <div className={style.subfactor} style={{color: '#70767b'}} key={sub.names}>
                                                    <span>{sub.names}</span>
                                                    <div>
                                                        {'\u25A0'.repeat(sub.score) + '\u25A1'.repeat(sub.maxScore - sub.score) + ' ' + sub.score + '/' + sub.maxScore}
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                )
                            }
                        </div>
                        <h2>Описание</h2>
                        {
                            BIG_FIVE_TEST_DESCRIPTION.map(item =>
                                <div className={style.description__item}>
                                    <span><b>{item.title}</b></span>
                                    {
                                        item.description.map(descr =>
                                            <div className={style.description__subitem}>
                                                <span><u>{descr.title}:</u></span>
                                                <p>{descr.description}</p>
                                            </div>
                                        )
                                    }
                                </div>
                            )
                        }
                        <div className={style.header}>
                            <h2>Тест Герчикова</h2>
                            <span>Потратил времени {data.gerchikov_test_result.completion_time}</span>
                        </div>
                        <div className={style.types}>
                            {
                                data.gerchikov_test_result.types.map(type =>
                                    <div className={style.type} style={{color: '#70767b'}} key={type.name}>
                                        <span style={{color: 'black'}}><u>{type.name}.</u></span>
                                        <div>
                                            {'\u25A0'.repeat(type.score) + '\u25A1'.repeat(type.maxScore - type.score) + ' ' + type.score + '/' + type.maxScore}
                                        </div>
                                        <p style={{color: 'black'}}>
                                            {type.description}
                                        </p>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                    <button className={style.btn} onClick={gen}>Скачать Word</button>
                </div>
            }
        </>
    );
};

export default ShortResult;