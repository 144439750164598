import React, {useRef} from 'react';
import style from './big-five-result.module.scss';
import {asBlob} from "html-docx-js-typescript";
import {saveAs} from "file-saver";
import {useGetBigFiveResultQuery} from "../../../services/resultsService";
import {BIG_FIVE_TEST_DESCRIPTION} from "../../../data/BIG_FIVE_TEST_DESCRIPTION";

interface Props {
    id: number,
    soloResult: boolean
}

const BigFiveResult = ({id, soloResult}: Props) => {
    const {data: bigFiveResult} = useGetBigFiveResultQuery(id);
    const resRef = useRef<HTMLDivElement>(null);
    const options = [-2, -1, 0, 1, 2];

    const gen = () => {
        const html = resRef.current!.innerHTML
        asBlob(html).then(data => {
            // @ts-ignore
            saveAs(data, `Пятифакторный тест - ${bigFiveResult?.user_name}.docx`)
        })
    }

    return (
        <>
            {
                bigFiveResult?.completion_time &&

                <div className={style.result}>
                    <div ref={resRef} className={style.body}>
                        <div className={style.header}>
                            <h2>Пятифакторный тест - {bigFiveResult.user_name}</h2>
                            <span>Потратил времени {bigFiveResult.completion_time}</span>
                        </div>
                        <div className={style.factors}>
                            {
                                bigFiveResult.factors.map((factor, index) =>
                                    <div key={index} className={style.factor}
                                         style={{borderBottom: '1px solid #ccd0d3'}}>
                                        <span><u>{factor.name} {factor.score}/{factor.maxScore}</u></span>
                                        {
                                            factor.subfactors.map(sub =>
                                                <div className={style.subfactor} style={{color: '#70767b'}}
                                                     key={sub.names}>
                                                    <span>{sub.names}</span>
                                                    <div>
                                                        {'\u25A0'.repeat(sub.score) + '\u25A1'.repeat(sub.maxScore - sub.score) + ' ' + sub.score + '/' + sub.maxScore}
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                )
                            }
                        </div>
                        <h2>Описание</h2>
                        {
                            BIG_FIVE_TEST_DESCRIPTION.map(item =>
                                <div className={style.description__item}>
                                    <span><b>{item.title}</b></span>
                                    {
                                        item.description.map(descr =>
                                            <div className={style.description__subitem}>
                                                <span><u>{descr.title}:</u></span>
                                                <p>{descr.description}</p>
                                            </div>
                                        )
                                    }
                                </div>
                            )
                        }
                        <h2>Ответы пользователя:</h2>
                        <div className={style.answers}>
                            {
                                bigFiveResult.answers.map((item) =>
                                    <div className={style.answer} style={{
                                        borderBottom: '1px solid #ccd0d3',
                                        marginTop: '5px',
                                        color: '#70767b'
                                    }}>
                                        {/*<span><u> Вопрос {item.question_id}</u></span>*/}
                                        <p>
                                            <span style={{marginRight: '20px'}}> {item.statement} </span>
                                            {
                                                options.map(option =>
                                                    item.value == option ?
                                                        <span style={{
                                                            marginLeft: '5px',
                                                            color: 'black'
                                                        }}><u><strong>[{option}]</strong></u></span>
                                                        :
                                                        <span style={{marginLeft: '5px'}}> [{option}] </span>
                                                )
                                            }
                                            <span style={{marginLeft: '20px'}}> {item.alt_statement} </span>
                                        </p>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                    {
                        soloResult &&
                        <button className={style.btn} onClick={gen}>Скачать Word</button>
                    }
                </div>
            }
        </>
    );
};

export default BigFiveResult;