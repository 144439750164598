import React, {useEffect, useRef} from 'react';
import style from './adizes-result.module.scss';
import {useGetAdizesResultQuery} from "../../../services/resultsService";
import {asBlob} from "html-docx-js-typescript";
import {saveAs} from "file-saver";

interface Props {
    id: number,
    soloResult: boolean
}

const AdizesResult = ({id, soloResult}: Props) => {
    const {data: adizResult} = useGetAdizesResultQuery(id);
    const resRef = useRef<HTMLDivElement>(null);

    const get = () => {
        const html = resRef.current!.innerHTML;
        asBlob(html).then(data => {
            // @ts-ignore
            saveAs(data, `Адизес - ${adizResult?.user_name}.docx`)
        })
    }


    return (
        <>
            {
                adizResult?.completion_time &&

                <div className={style.result}>
                    <div ref={resRef} className={style.body}>
                        <div className={style.header}>
                            <h2>Тест Адизеса - {adizResult?.user_name}</h2>
                            <span>Потратил времени {adizResult?.completion_time}</span>
                        </div>
                        <span>Код: {adizResult.key}</span>
                        <div className={style.keys}>
                            {Object.values(adizResult.keys).map(item =>
                                 <div className={style.keys__item}>
                                     <div className={style.underline}><u>{item.name + ': ' + item.score + ' ' + item.textScore}</u></div>
                                     <div>{item.description}</div>
                                 </div>
                             )}
                        </div>
                        <h2>Ответы пользователя</h2>
                       <div className={style.answers}>
                           {
                               adizResult.answers.map((answer, index) =>
                                <div key={index} className={style.answers__item}>
                                    <div className={style['question-index']}><b>Вопрос {index + 1}</b></div>
                                    {
                                        answer.map(item =>
                                            <div className={style.answer} key={item.question}>
                                                <span>[{item.value}]</span>
                                                <span>  </span>
                                                <span>{item.question}</span>
                                            </div>
                                        )
                                    }
                                </div>
                               )
                           }
                       </div>
                    </div>
                    {
                        soloResult &&
                    <button className={style.btn} onClick={get}>Скачать Word</button>
                    }
                </div>
            }
        </>
    );
};

export default AdizesResult;