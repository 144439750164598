import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {baseUrl} from "../constants/constants";
import {IUser} from "../types/user";
import queryString from 'query-string';
import {UpdateForm} from "../components/users/update-user/update-user";
import {baseQueryWithReAuth} from "./inerceptor";



export const userApi = createApi({
    reducerPath: 'userApi',
    tagTypes: ['Users'],
    baseQuery: baseQueryWithReAuth,
    endpoints: (builder) => ({
        getAllUsers: builder.query<IUser[], {
            page: number,
            departments: number[] | null,
            vacancies: string[] | null,
            tags: string[] | null,
            isActive: boolean,
            fio: string,
        }>({
            // @ts-ignore
            query: ({page, departments, vacancies, tags, isActive, fio}) => ({
                url: `users`,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                },
                params: queryString.stringify({
                    page,
                    page_size: 100,
                    departments: departments,
                    vacancies,
                    tags,
                    is_active: isActive,
                    is_admin: false,
                    fio,
                }, {skipNull: true})
            }),
            providesTags: (result) =>
                result
                    ? [...result.map(({ id }) => ({ type: 'Users' as const, id })), 'Users']
                    : ['Users'],
        }),
        createUser: builder.mutation({
            query: (body) => ({
                url: 'users',
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                },
                body,
            }),
            invalidatesTags: [{type: 'Users'}]
        }),
        updateUser: builder.mutation({
            query: ({id, ...body}) => ({
                url: `users/${id}`,
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                },
                body,
            }),
            invalidatesTags: [{type: 'Users'}]
        })
    }),
})

export const {useGetAllUsersQuery, useCreateUserMutation, useUpdateUserMutation} = userApi;