import React from 'react';
import style from './auth.module.scss';
import {SubmitHandler, useForm} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import {baseUrl} from "../../constants/constants";

interface Inputs {
    username: string,
    password: string
}

const Auth = () => {
    const {register, handleSubmit, formState: {errors}, setError} = useForm<Inputs>();
    const navigate = useNavigate();

    const submit: SubmitHandler<Inputs> = async (data) => {
        const res = await fetch(baseUrl + 'admins/login', {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data)
        });
        if (res.status === 200) {
            const data = await res.json();
            localStorage.setItem('token', data.access_token);
            setTimeout(() => {
                navigate('/');
            }, 100)
        } else if (res.status === 401) {
            setError('root', {type: 'custom', message: ''})
        }
    }

    return (
        <form className={style.form} onSubmit={handleSubmit(submit)}>
            <p className={style.header}>Авторизация</p>
            <span className={style.label}>Логин {errors.username && <p className={style.error}>Логин не может быть пустым</p>} </span>
            <input data-cy="login" className={style.input} type="text"  {...register('username', {required: true})}/>
            <span className={style.label}>Пароль {errors.password && <p className={style.error}>Пароль не может быть пустым</p>}</span>
            <input data-cy="password" className={style.input} type="password"
                   {...register('password', {required: true})}/>
            <div className={style.label}>
                {errors.root && !errors.username && !errors.password && <p  className={style.error + ' ' + style.error__cred}>Неверный логин или пароль</p>}
            </div>
            <button className={style.btn} type="submit">Войти</button>
        </form>
    );
};

export default Auth;