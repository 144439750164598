import React from 'react';
import style from './log.module.scss'
import {ILogs} from "../../../types/logs";
import {formatDateWithTime, getInitials} from "../../../utils/utils";

interface Params {
    log: ILogs
}

const Log = ({log}: Params) => {

    const actionDefinition = (str: string) => {
        switch (str) {
            case 'user_edit':
                return 'Редактирование пользователя';
            case 'user_create':
                return 'Создание';
            case 'form_edit':
                return 'Редактирование анкеты';
            case 'auth':
                return 'Вход';

        }
    }

    return (
        <div className={style.log} data-cy='simple-log'>
            <div className={style.user}>
                <div className={style.user__avatar}>{getInitials(log.fio)}</div>
                <div className={style.user__wrap}>
                    <span className={style.user__fio}>{log.fio}</span>
                    <span className={style.user__ip}>{log.ip_address}</span>
                </div>
            </div>
            <div className={style.time}>{formatDateWithTime(log.event_time)}</div>
            <div className={style['log-type']}>
                <div className={style[log.log_type] + ' ' + style['filter-btn']}>{actionDefinition(log.log_type)}</div>
            </div>
            <div className={style.message}>{log.message}</div>
        </div>
    );
};

export default Log;