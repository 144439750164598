import React, {useRef} from 'react';
import style from './gerchikov-result.module.scss';
import {useGetGerchikovResultQuery} from "../../../services/resultsService";
import {asBlob} from "html-docx-js-typescript";
import {saveAs} from "file-saver";
import {GERCHIKOV_TEST_DESCRIPTION} from "../../../data/GERCHIKOV_TEST_DESCRIPTION";

interface Props {
    id: number
    soloResult: boolean
}

const GerchikovResult = ({id, soloResult}: Props) => {
    const {data: gerchikovResult} = useGetGerchikovResultQuery(id);
    const resRef = useRef<HTMLDivElement>(null);

    const gen = () => {
        const html = resRef.current!.innerHTML
        asBlob(html).then(data => {
            // @ts-ignore
            saveAs(data, `Тест Герчикова - ${gerchikovResult?.user_name}.docx`)
        })
    }
    return (
        <>
            {
                gerchikovResult?.completion_time &&

                <div className={style.result}>
                    <div ref={resRef} className={style.body}>
                        <div className={style.header}>
                            <h2>Тест Герчикова - {gerchikovResult.user_name}</h2>
                            <span>Потратил времени {gerchikovResult.completion_time}</span>
                        </div>
                        <div className={style.types}>
                            {
                                gerchikovResult.types.map((type) =>
                                    <div className={style.type} style={{color: '#70767b'}} key={type.name}>
                                        <span style={{color: 'black'}}><u>{type.name}.</u></span>
                                        <div>
                                            {'\u25A0'.repeat(type.score) + '\u25A1'.repeat(type.maxScore - type.score) + ' ' + type.score + '/' + type.maxScore}
                                        </div>
                                        <p style={{color: 'black'}}>
                                            {type.description}
                                        </p>
                                    </div>
                                )
                            }
                        </div>
                        <h2>Ответы пользователя:</h2>
                        <div className={style.answers}>
                            {
                                gerchikovResult.answers.map((answer, index) =>
                                    <div className={style.answer}>
                                        <span>{index + 1}. {answer.question}</span>
                                        <div className={style.options}>
                                            {
                                                answer.options.map(option =>
                                                    option.selected ?
                                                        <div key={option.text}>[<b>+</b>] {option.text}</div>
                                                        :
                                                        <div key={option.text}>[{' '}] {option.text}</div>
                                                )
                                            }
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                    {
                        soloResult &&
                        <button className={style.btn} onClick={gen}>Скачать Word</button>
                    }
                </div>
            }
        </>
    );
};

export default GerchikovResult;