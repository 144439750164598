import {createApi} from "@reduxjs/toolkit/query/react";
import {IDepartments} from "../types/departments";
import {baseQueryWithReAuth} from "./inerceptor";


export const departmentsApi = createApi({
    reducerPath: 'departmentsApi',
    tagTypes: ['Departments'],
    baseQuery: baseQueryWithReAuth,
    endpoints: (builder) => ({
        getDepartments: builder.query<IDepartments[], {}>({
            query: () => ({
                url: 'user_departments',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                },
            }),
            providesTags: (result) =>
                result
                    ? [...result.map(({ id }) => ({ type: 'Departments' as const, id })), 'Departments']
                    : ['Departments'],
        }),
        createDepartment: builder.mutation({
            query: (body) => ({
                url: 'user_departments',
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                },
                body,
            }),
            invalidatesTags: [{type: 'Departments'}],
        }),
        deleteDepartment: builder.mutation({
            query: (id) => ({
                url: `user_departments/${id}`,
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                },
            }),
            invalidatesTags: [{type: 'Departments'}],
        })
    })
})

export const {useGetDepartmentsQuery, useCreateDepartmentMutation, useDeleteDepartmentMutation} = departmentsApi;