import React from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Layout from "./components/layout/layout";
import Auth from "./pages/auth/auth";
import PrivateRoutes from "./utils/privateRoute";
import Home from "./pages/home/home";
import Logs from "./pages/logs/logs";
import Form from "./pages/form/form";

function App() {
    return (
            <BrowserRouter>
                <Routes>
                    <Route path='auth' element={<Auth />}/>
                    <Route element={<PrivateRoutes/>}>
                        <Route path="/" element={<Layout />}>
                            <Route path="/" element={<Home />}/>
                            <Route path="/logs" element={<Logs />}/>
                            <Route path="/form" element={<Form />}/>
                        </Route>
                    </Route>
                </Routes>
            </BrowserRouter>
    );
}

export default App;
