import React, {useEffect, useState} from 'react';
import style from './home.module.scss';
import {FaFilter, FaUserPlus} from "react-icons/fa";
import {IoIosSearch} from "react-icons/io";
import Table from "../../components/table/table";
import {useNavigate} from "react-router-dom";
import {useGetAllUsersQuery} from "../../services/userService";
import UserFilter from "../../components/users/users-filter/userFilter";
import {useGetVacanciesQuery} from "../../services/vacanciesService";
import {useGetDepartmentsQuery} from "../../services/departmentsService";
import {useGetTagsQuery} from "../../services/tagsService";
import CreateUser from "../../components/users/create-user/create-user";
import {ToastContainer} from "react-toastify";
import DepartmentsCreate from "../../components/departments-create/departments-create";


const Home = () => {
    const [params, setParams] = useState({
        page: 1,
        departments: null,
        vacancies: null,
        tags: null,
        isActive: true,
        fio: '',
    });
    const [searchInput, setSearchInput] = useState('');
    const navigate = useNavigate();
    const {data: users, isError, error, } = useGetAllUsersQuery(params);
    const {data: vacancies} = useGetVacanciesQuery('');
    const {data: departments} = useGetDepartmentsQuery('');
    const {data: tags} = useGetTagsQuery('');
    const [filterMenuIsActive, setFilterMenuIsActive] = useState(false);
    const [modalCreate, setModalCreate] = useState(false);
    const [departmentModalCrud, setDepatmentModalCrud] = useState(false);

    const closeDepartmentModal = () => {
        setDepatmentModalCrud(false);
    }

    const setActive = () => {
        setParams({...params, isActive: true, page: 1})
    };

    const setArchive = () => {
        setParams({...params, isActive: false, page: 1})
    };

    useEffect(() => {
        const debounce = setTimeout(() => {
            setParams({...params, fio: searchInput})
        }, 300);

        return () => clearTimeout(debounce);
    }, [searchInput]);

    const closeModalCreateUser = () => {
        setModalCreate(false);
    };

    return (
        <div className={filterMenuIsActive ? style.home + ' ' + style['home-filters'] : style.home}>
            <div className={style.header}>
                <h2>Результаты тестирования</h2>
                <button className={style.btn + ' ' + style.createBtn} onClick={() => setModalCreate(true)}>
                    <FaUserPlus />
                    <span>Создать</span>
                </button>
            </div>

            <div className={style.options}>
                <div className={style.options__left}>
                    <button
                        className={params.isActive ? style.btn + ' ' + style['btn-active'] : style.btn}
                        onClick={setActive}
                    >Активные</button>

                    <button
                        className={!params.isActive ? style.btn + ' ' + style['btn-active'] : style.btn}
                        onClick={setArchive}
                    >Архив</button>
                </div>
                <div className={style.options__right}>
                    <div className={style.options__top}>
                        <button className={style.btn} onClick={() => setFilterMenuIsActive(!filterMenuIsActive)}><FaFilter /> Фильтры</button>
                        <label data-cy='user_search' className={style.search}>
                            <IoIosSearch size={24} />
                            <input
                                type="search"
                                className={style.search__input}
                                placeholder="Поиск пользователей"
                                value={searchInput}
                                onChange={(e) => setSearchInput(e.target.value)}
                            />
                        </label>
                    </div>
                    <div className={style.options__bottom}>
                        <button onClick={() => setDepatmentModalCrud(true)}>Подразделения</button>
                    </div>
                </div>
            </div>
            {
                filterMenuIsActive &&

                <div className={style.filters}>
                    <div data-cy="vacancy" className={style.filters__item}>
                      <UserFilter data={vacancies} name={'Вакансия'} params={params} setParams={setParams} filterName={'vacancies'}/>
                    </div>
                    <div data-cy="department" className={style.filters__item}>
                        <UserFilter data={departments} name={'Отдел'} params={params} setParams={setParams} filterName={'departments'}/>
                    </div>
                    <div data-cy="tag" className={style.filters__item}>
                        <UserFilter data={tags} name={"Тэг"} params={params} setParams={setParams} filterName={'tags'}/>
                    </div>
                </div>
            }

            <Table data={users} params={params} setParams={setParams}/>
            <CreateUser active={modalCreate} close={closeModalCreateUser} departments={departments}/>
            <DepartmentsCreate departments={departments} close={closeDepartmentModal} active={departmentModalCrud}/>
            <ToastContainer
                position="bottom-left"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </div>
    );
};

export default Home;