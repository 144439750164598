import {configureStore} from "@reduxjs/toolkit";
import {TypedUseSelectorHook, useDispatch, useSelector} from "react-redux";
import {userApi} from "./services/userService";
import {vacanciesApi} from "./services/vacanciesService";
import {departmentsApi} from "./services/departmentsService";
import {tagsApi} from "./services/tagsService";
import {logsApi} from "./services/logs";
import {formApi} from "./services/form";
import {resultsApi} from "./services/resultsService";


export const store = configureStore({
    reducer: {
        [userApi.reducerPath]: userApi.reducer,
        [vacanciesApi.reducerPath]: vacanciesApi.reducer,
        [departmentsApi.reducerPath]: departmentsApi.reducer,
        [tagsApi.reducerPath]: tagsApi.reducer,
        [logsApi.reducerPath]: logsApi.reducer,
        [formApi.reducerPath]: formApi.reducer,
        [resultsApi.reducerPath]: resultsApi.reducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat([userApi.middleware, vacanciesApi.middleware, departmentsApi.middleware, tagsApi.middleware, logsApi.middleware, formApi.middleware, resultsApi.middleware]),
    devTools: true,
})

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;