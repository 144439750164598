import React, {useState} from 'react';
import style from './form.module.scss';
import {LuBookPlus} from "react-icons/lu";
import {useGetQuestionsQuery} from "../../services/form";
import Question from "../../components/form/question";
import CreateQuestion from "../../components/form/createQestion/createQuestion";


const Form = () => {
    const {data: questions} = useGetQuestionsQuery('');
    const [modalCreate, setModalCreate] = useState(false);

    const closeModal = () => {
        setModalCreate(false);
    }


    return (
        <div className={style.form}>
           <div className={style.header}>
               <h2>Анкета</h2>
               <button className={style.btn} onClick={() => setModalCreate(true)}>
                   <span>Новый вопрос</span>
                   <LuBookPlus />
               </button>
           </div>
            <div className={style.questions}>
                {
                    questions?.map((item, index) =>
                        <Question key={item.id} id={item.id} text={item.text} questionNumber={index}/>
                    )
                }
            </div>
            <CreateQuestion active={modalCreate} close={closeModal} />
        </div>
    );
};

export default Form;