import './header.scss';
import { FaHome } from "react-icons/fa";
import { GiBackwardTime } from "react-icons/gi";
import { CiLogout } from "react-icons/ci";
import {NavLink, useNavigate} from "react-router-dom";
import { FaRegAddressBook } from "react-icons/fa6";
import { RxHamburgerMenu } from "react-icons/rx";
import { useState } from 'react';


export const Header = () => {
    const navigate = useNavigate();

    const logout = () => {
        localStorage.clear();
        navigate('/auth');
    }

    const [navPanelActive, setNavPanelActive] = useState(false);

    return (
        <header className={!navPanelActive ? ' ' : 'close-nav'}>
            <button id='burger' onClick={() => setNavPanelActive(!navPanelActive)}>
                <RxHamburgerMenu />
            </button>
            <h1 className="website-logo">
                <span className="website-logo__top">HR</span>
                <span className="website-logo__bottom">board</span>
            </h1>
            <nav className="navigation-panel">
                <ul className="nav-top">
                    <NavLink to='/' className="li-item" data-cy='nav-to-home'>
                        <li ><FaHome color='white'/></li>
                    </NavLink>
                    <NavLink to='/logs' className="li-item" data-cy='nav-to-logs'>
                        <li><GiBackwardTime  color='white' size={45}/></li>
                    </NavLink>
                    <NavLink to='/form' className="li-item" data-cy='nav-to-form'>
                        <li><FaRegAddressBook  color='white'/></li>
                    </NavLink>
                </ul>
                <ul className="nav-bottom">
                    <li className="li-item" onClick={logout}><CiLogout /></li>
                </ul>
            </nav>
        </header>
    )
};

