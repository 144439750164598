import React from 'react';
import Modal from "../modal/modal";
import {IDepartments} from "../../types/departments";
import {SubmitHandler, useForm} from "react-hook-form";
import style from "./departments-create.module.scss";
import {useCreateDepartmentMutation, useDeleteDepartmentMutation} from "../../services/departmentsService";
import {infoToast, successToast} from "../../utils/toastify";

interface Props {
    active: boolean,
    close: () => void,
    departments: IDepartments[] | undefined
}

interface Form {
    name: string,
}

interface DeleteForm {
    department_id: number,
}

const DepartmentsCreate = ({active, close, departments}: Props) => {
    const {register, handleSubmit, formState: {errors}, reset} = useForm<Form>();
    const {register: deleteReg, handleSubmit: deleteSubmit, formState: {errors: deleteError}, reset: delReset} = useForm<DeleteForm>();
    const [createDep] = useCreateDepartmentMutation();
    const [deleteDep] = useDeleteDepartmentMutation();


    const createDepartment: SubmitHandler<Form> =  async data => {
        try {
            const result = await createDep(data).unwrap();
            successToast('Подразделение создан');
            reset();
        } catch (e) {
            console.log(e);
        }
    }

    const deleteDepartment: SubmitHandler<DeleteForm> =  async data => {
        try {
            const result = await deleteDep(data.department_id).unwrap();
            infoToast('Подразделение удалено');
            delReset();
        } catch (e) {
            console.log(e);
        }
    }



    return (
        <Modal active={active} close={close} title='Редактировать подразделения'>
            <div className={style.create} >
                <form onSubmit={handleSubmit(createDepartment)} className={style.create__item}>
                        <div className={style.create__label}>
                            <span>Название подразделения</span>
                        </div>
                        <input type="text" className={style.create__input}
                               placeholder="WebDev" {...register('name', {required: true})}/>
                        {errors.name && <span className={style.create__error}>Введите название подразделения</span>}
                    <button className={style.btn  + ' ' + style.createBtn}>Добавить</button>
                </form>
                <form onSubmit={deleteSubmit(deleteDepartment)} className={style.create__item}>
                    <div className={style.create__label}>
                        <span>Выберите подразделение для удаления:</span>
                    </div>
                    {deleteError.department_id && <span className={style.create__error}>Выберите подразделение</span>}
                    <select className={style.create__input}
                            {...deleteReg('department_id', {required: true})}
                            defaultValue={''}
                    >
                        <option value="" disabled>Подразделение не выбрано</option>
                        {
                            departments?.map(item =>
                                <option key={item.id} value={item.id} className={style.create__option}>
                                    {item.name}
                                </option>
                            )
                        }
                    </select>
                    <button className={style.btn + ' ' + style.deleteBtn}>Удалить</button>
                </form>
            </div>
        </Modal>
    );
};

export default DepartmentsCreate;