import React, {PropsWithChildren} from 'react';
import style from './modal.module.scss';
import { MdOutlineClose } from "react-icons/md";

interface Props {
    active: boolean,
    title: string,
    close: () => void;
}

const Modal = ({active, title, close, children}: PropsWithChildren<Props>) => {
    if (!active) {
        return null;
    }
    return (
        <div data-cy='modal' className={style.modal} onClick={close}>
            <div data-cy='modal-content' className={style.modal__content} onClick={e => e.stopPropagation()}>
                <div className={style.modal__header}>
                    <span>{title}</span>
                </div>
                <div className={style.modal__body}>
                    {children}
                </div>
            </div>
        </div>
    );
};

export default Modal;