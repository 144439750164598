import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {baseUrl} from "../constants/constants";
import {ITags} from "../types/tags";
import {baseQueryWithReAuth} from "./inerceptor";


export const tagsApi = createApi({
    reducerPath: 'tagsApi',
    baseQuery: baseQueryWithReAuth,
    endpoints: (builder) => ({
        getTags: builder.query<ITags[], {}>({
            query: () => ({
                url: 'tags',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                },
            })
        })
    })
})

export const {useGetTagsQuery} = tagsApi;