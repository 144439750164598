import React, {useEffect, useRef, useState} from 'react';
import {IUser} from "../../../types/user";
import style from './table-item.module.scss';
import {formatDateString, getInitials} from "../../../utils/utils";
import {MdOutlineDone} from "react-icons/md";
import {FaPause} from "react-icons/fa";
import {RxCross2} from "react-icons/rx";
import {infoToast, successToast} from "../../../utils/toastify";
import UpdateUser from "../../users/update-user/update-user";
import {useGetDepartmentsQuery} from "../../../services/departmentsService";
import {useUpdateUserMutation} from "../../../services/userService";
import {HiDotsVertical} from "react-icons/hi";
import {HiDotsHorizontal} from "react-icons/hi";
import {CiSettings} from "react-icons/ci";
import {IoSettingsSharp} from "react-icons/io5";
import {useClickOutside} from "../../../hooks/useClickOutside";
import FormResult from "../../results/form/form-result";
import {useGetFormResultQuery} from "../../../services/resultsService";
import Modal from "../../modal/modal";
import AdizesResult from "../../results/adizes-result/adizes-result";
import BigFiveResult from "../../results/big-five-result/big-five-result";
import GerchikovResult from "../../results/gerchikov-result/gerchikov-result";
import FullResult from "../../results/full-result/full-result";
import ShortResult from "../../results/short-result/short-result";

interface Props {
    user: IUser;
}

const TableItem = ({user}: Props) => {
    const [editActive, setEditActive] = useState(false);
    const [modalUpdate, setModalUpdate] = useState(false);
    const {data: departments} = useGetDepartmentsQuery('');
    const [updateUser] = useUpdateUserMutation();
    const [btnDisabled, setBtnDisabled] = useState(false);
    const [settingsClick, setSettingsClick] = useState(false);
    const popupRef = useRef(null);
    const btnRef = useRef(null);

    const [formResultModal, setFormResultModal] = useState(false);
    const closeForm = () => {
        setFormResultModal(false);
    }

    const [adizesResultModal, setAdizesResultModal] = useState(false);
    const closeAdizesModal = () => {
        setAdizesResultModal(false);
    }

    const [bigFiveModal, setBigFiveModal] = useState(false);
    const closeBigFiveModal = () => {
        setBigFiveModal(false);
    }

    const [gerchikovModal, setGerchikovModal] = useState(false);
    const closeGerchikovModal = () => {
        setGerchikovModal(false);
    }
    const [fullResModal, setFullResModal] = useState(false);
    const closeFullResModal = () => {
        setFullResModal(false);
    }

    const [shortResModal, setShortResModal] = useState(false);
    const closeShortResModal = () => {
      setShortResModal(false);
    }


    const {data: formResult} = useGetFormResultQuery(user.id, {skip: !formResultModal});


    const resolveUserStatus = () => {
        const allCompleted =
            user.tests_status.adizes_test &&
            user.tests_status.big_five_test &&
            user.tests_status.gerchikov_test &&
            user.tests_status.security_form;
        const userExpired = new Date() > new Date(user.deactivation_date);
        switch (true) {
            case allCompleted: {
                return 'success';
            }
            case !allCompleted && !userExpired:
                return 'neutral';
            case userExpired && !allCompleted:
                return 'bad';
        }
        return 'neutral';
    }

    const copyToClipboard = (code: string, e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
        e.preventDefault()
        navigator.clipboard.writeText(code);
        successToast('Код скопирован');
    }

    const closeModalUpdateUser = () => {
        setModalUpdate(false);
    }

    const sendToArchive = async () => {
        const id = user.id;
        const data = {is_active: false};
        setBtnDisabled(true);
        const result = await updateUser({id, ...data}).unwrap();
        infoToast('Пользователь помещен в архив');
    }

    const sendToHome = async () => {
        const id = user.id;
        const data = {is_active: true};
        setBtnDisabled(true);
        const result = await updateUser({id, ...data}).unwrap();
        infoToast('Пользователь убран из архива');
    }

    useClickOutside(popupRef, btnRef, settingsClick, setSettingsClick);

    return (
        <>
            <tr className={style.row} onMouseEnter={() => setEditActive(true)}
                onMouseLeave={() => setEditActive(false)}>
                <td className={style.cell}>
                    {
                        editActive &&
                        <div onClick={() => setSettingsClick(!settingsClick)}
                             className={style.user__details}
                             ref={btnRef}
                        >
                            <CiSettings size={22}/>
                        </div>
                    }
                    {
                        settingsClick &&
                        <div className={style.user__wrap + ' ' + style.edit} ref={popupRef}>
                            <button data-cy='settings_button' onClick={() => setModalUpdate(true)}>Редактировать</button>
                            {
                                user.is_active ?
                                    <button onClick={sendToArchive} disabled={btnDisabled}>В архив</button>
                                    :
                                    <button onClick={sendToHome} disabled={btnDisabled}>В активные</button>
                            }

                        </div>
                    }
                    <div className={style.user}>
                        <div className={style.user__avatar}>
                            {getInitials(user.fio)}
                        </div>
                        <div className={style.user__wrap}>
                            <span className={style.user__fio}>{user.fio}</span>
                            <span className={style.user__hashcode}
                                  onClick={(e) => copyToClipboard(user.hashcode, e)}>{user.hashcode}</span>
                            <span className={style.user__description} title={user.description}>{user.description}</span>
                        </div>
                    </div>
                </td>
                <td className={style.cell + ' ' + style.user__vacancy}>{user.vacancy_name}</td>
                <td className={style.cell + ' ' + style.user__department}>
                    {
                        user.department_info ? user.department_info.name : 'Не указан'
                    }
                </td>
                <td className={style.cell}>{formatDateString(user.date_joined)}</td>
                <td className={style.cell + ' ' + style.status}>
                    {
                        resolveUserStatus() === 'success' && <div className={style.done}>
                            <MdOutlineDone/>
                            <span className={style.status__text}>Выполнено</span>
                        </div>

                    }
                    {
                        resolveUserStatus() === 'neutral' && <div className={style.inprogress}>
                            <FaPause/>
                            <span className={style.status__text}>В процессе</span>
                        </div>
                    }
                    {
                        resolveUserStatus() === 'bad' && <div className={style.expired}>
                            <RxCross2/>
                            <span className={style.status__text}>Истёк</span>
                        </div>
                    }
                </td>
                <td className={style.cell + ' ' + style.user__result}>
                    {
                        user.tests_status.adizes_test &&
                        user.tests_status.big_five_test &&
                        user.tests_status.gerchikov_test &&
                        <button data-cy='button__short-res' className={style.user__btn} onClick={() => setShortResModal(true)}>{user.short_result}</button>
                    }
                    {
                        user.tests_status.adizes_test &&
                        user.tests_status.big_five_test &&
                        user.tests_status.gerchikov_test &&
                        <button data-cy='button__full-res' className={style.user__btn} onClick={() => setFullResModal(true)}>Полный</button>
                    }
                    {
                        user.tests_status.security_form &&
                        <button data-cy='button__questionnaire' className={style.user__btn} onClick={() => setFormResultModal(true)}>Анкета</button>
                    }
                </td>
            </tr>
            <UpdateUser active={modalUpdate} close={closeModalUpdateUser} departments={departments} user={user}/>
            <Modal title='Результаты' active={formResultModal} close={closeForm}>
                <FormResult formResult={formResult}/>
            </Modal>
            <Modal active={fullResModal} close={closeFullResModal} title={'Все тесты'}>
                <FullResult id={user.id} name={user.fio}/>
            </Modal>
            <Modal active={shortResModal} title='Краткие результаты' close={closeShortResModal}>
                <ShortResult id={user.id} />
            </Modal>
        </>
    );
};

export default TableItem;