import React, {useEffect} from "react";


export type Ref = React.MutableRefObject<HTMLDivElement | null>;

export const useClickOutside = (popupRef: Ref, btnRef: Ref, showPopup: boolean, setShowPopup: React.Dispatch<React.SetStateAction<boolean>>) => {

    const handleClickOutside = (event: MouseEvent) => {
        if (popupRef.current && !popupRef.current.contains(event.target as Node) && btnRef.current !== event.target) {
            setShowPopup(false);
        }
    };

    useEffect(() => {
        if (showPopup) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showPopup]);
}