import React, {useEffect, useRef, useState} from 'react';
import style from './userFilter.module.scss';
import {RxCross2} from "react-icons/rx";
import {RiArrowDropDownLine} from "react-icons/ri";
import {useClickOutside} from "../../../hooks/useClickOutside";

interface Params {
    page: number,
    departments: null,
    vacancies: null,
    tags: null,
    isActive: boolean,
    fio: string
}

interface Filter {
    id: number,
    name: string,
}

interface Props {
    data: Filter[] | undefined,
    name: string,
    params: Params,
    setParams: React.Dispatch<React.SetStateAction<Params>>,
    filterName: string,
}

const UserFilter = ({data, name, params, setParams, filterName}: Props) => {
    const [showFilters, setShowFilters] = useState(false);
    const [tags, setTags] = useState<string[]>([]);
    const popupRef = useRef<HTMLDivElement | null>(null);
    const btnRef = useRef<HTMLDivElement | null>(null);
    const [dataCopy, setDataCopy] = useState(data);


    useClickOutside(popupRef, btnRef, showFilters, setShowFilters);

    useEffect(() => {
        setParams({...params, [filterName]: tags})
    }, [tags]);

    const addTag = (name: string) => {
        setTags([...tags, name]);
        setDataCopy(dataCopy?.filter(item => item.name !== name));
        console.log(dataCopy);

    }

    const deleteTag = (name: string) => {
        setTags(tags.filter(item => item !== name));
        // @ts-ignore
        setDataCopy([...dataCopy, {id: new Date(), name: name}]);
    }


    return (
        <div className={style.filter}>
            <span className={style.filter__text}>{name}</span>
            <div className={showFilters ? style.input + ' ' + style.open : style.input}
                 onClick={() => setShowFilters(!showFilters)} ref={btnRef}>
                {
                    !!tags.length &&
                    <>
                        {tags.map(item =>
                            <div className={style.tag} key={item}>
                                <span className={style.tag__text}>{item}</span>
                                <RxCross2 data-cy='del-button' className={style.tag__icon} onClick={() => deleteTag(item)}/>
                            </div>
                        )}
                    </>
                }
                <RiArrowDropDownLine className={style.input__icon}/>
                {showFilters &&
                    <div className={style['filter-list']} onClick={e => e.stopPropagation()} ref={popupRef}>
                        {
                            dataCopy?.map(item =>
                                <div className={style['filter-list__item']} key={item.id}
                                     onClick={() => addTag(item.name)}>
                                    {item.name}
                                </div>
                            )
                        }
                    </div>
                }
            </div>
        </div>
    );
};

export default UserFilter;