import React, {useState} from 'react';
import Modal from "../../modal/modal";
import style from "./createQuestion.module.scss";
import {useCreateQuestionMutation} from "../../../services/form";

interface Props {
    active: boolean,
    close: () => void,
}

const CreateQuestion = ({active, close}: Props) => {
    const [questionText, setQuestionText] = useState('');
    const [create] = useCreateQuestionMutation();

    const createQuestion = async () => {
        try {
            if (questionText) {
                await create({text: questionText});
                setQuestionText('');
                close();
            }
        } catch (e) {
            console.log(e);
        }
    }

    return (
        <Modal active={active} close={close} title='Добавить вопрос в анкету'>
            <textarea className={style.text}
                      placeholder='Введите вопрос'
                      value={questionText}
                      onChange={(e) => setQuestionText(e.target.value)}>
            </textarea>
            <button className={style.btn} onClick={createQuestion}>Добавить</button>
        </Modal>
    );
};

export default CreateQuestion;