import React, {useEffect, useRef} from 'react';
import style from './table.module.scss';
import TableItem from "./table-item/table-item";
import {IUser} from "../../types/user";
import {GrLinkNext, GrLinkPrevious} from "react-icons/gr";

interface Params {
    page: number,
    departments: null,
    vacancies: null,
    tags: null,
    isActive: boolean,
    fio: string
}

interface Props {
    data: IUser [] | undefined,
    params: Params,
    setParams: React.Dispatch<React.SetStateAction<Params>>
}

const Table = ({data, params, setParams}: Props) => {
    const pageRef = useRef<HTMLDivElement>(null);

    const scrollToTop = () => {
        if (pageRef.current) {
            pageRef.current.scrollTop = 0;
        }
    };

    return (
        <div className={style.users} ref={pageRef}>
            <table className={style.table}>
                <thead className={style.table__head}>
                    <tr className={style.table__row}>
                        <td className={style.table__cell}>Кандидат</td>
                        <td className={style.table__cell}>Вакансия</td>
                        <td className={style.table__cell}>Подразделение</td>
                        <td className={style.table__cell}>Зарегистрирован</td>
                        <td className={style.table__cell}>Статус</td>
                        <td className={style.table__cell}>Результаты</td>
                    </tr>
                </thead>
                <tbody className={style.table__body}>
                {
                    data?.map(item =>
                        <TableItem user={item} key={item.id}/>
                    )
                }
                </tbody>
            </table>
            <div className={style.pages}>
                {
                    params.page > 1 &&
                    <div className={style.pages__item} onClick={() => {
                        setParams({...params, page: params.page - 1})
                        scrollToTop();
                    }}>
                        <GrLinkPrevious/>
                        <span>Предыдущая страница</span>
                    </div>
                }
                {
                    data?.length === 100 &&
                    <div className={style.pages__item} onClick={() => {
                        setParams({...params, page: params.page + 1});
                        scrollToTop();
                    }}>
                        <span>Следующая страница</span>
                        <GrLinkNext/>
                    </div>
                }
            </div>
        </div>
    );
};

export default Table;