import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {baseUrl} from "../constants/constants";
import {IForm} from "../types/form";
import {baseQueryWithReAuth} from "./inerceptor";


export const formApi = createApi({
    reducerPath: 'formApi',
    tagTypes: ['Form'],
    baseQuery: baseQueryWithReAuth,
    endpoints: (builder) => ({
        getQuestions: builder.query<IForm[], {}>({
            query: () => ({
                url: 'security_form/questions',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                },
            }),
            providesTags: (result) =>
                result
                    ? [...result.map(({ id }) => ({ type: 'Form' as const, id })), 'Form']
                    : ['Form'],
        }),
        createQuestion: builder.mutation({
            query: (body) => ({
                url: 'security_form/questions' ,
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                },
                body,
            }),
            invalidatesTags: [{type: 'Form'}],
        }),
        updateQuestion: builder.mutation({
            query: ({id, ...body}) => ({
                url: `security_form/questions/${id}`,
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                },
                body,
            }),
            invalidatesTags: [{type: 'Form'}],
        }),
        deleteQuestion: builder.mutation({
            query: (id) => ({
                url: `security_form/questions/${id}`,
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                },
            }),
            invalidatesTags: [{type: 'Form'}],
        })
    })
})

export const {useGetQuestionsQuery, useCreateQuestionMutation, useUpdateQuestionMutation, useDeleteQuestionMutation} = formApi;