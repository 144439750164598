import React, {useEffect, useRef, useState} from 'react';
import style from './full-result.module.scss';
import AdizesResult from "../adizes-result/adizes-result";
import BigFiveResult from "../big-five-result/big-five-result";
import GerchikovResult from "../gerchikov-result/gerchikov-result";
import {asBlob} from "html-docx-js-typescript";
import {saveAs} from "file-saver";

interface Props {
    id: number,
    name: string
}

const FullResult = ({id, name}: Props) => {
    const fullResRef = useRef<HTMLDivElement>(null);
    const [showBtn, setShowBtn] = useState(false);

    const gen = () => {
        const html = fullResRef.current!.innerHTML
        asBlob(html).then(data => {
            // @ts-ignore
            saveAs(data, `Все тесты - ${name}.docx`)
        })
    }

    useEffect(() => {
        const timeout = setTimeout(() => {
            setShowBtn(true);
        }, 400)

        return () => clearTimeout(timeout);
    }, []);

    return (
        <div className={style.result}>
            <div ref={fullResRef} className={style.body}>
                <AdizesResult soloResult={false} id={id}/>
                <BigFiveResult soloResult={false} id={id}/>
                <GerchikovResult soloResult={false} id={id}/>
            </div>
            {
                showBtn && <button className={style.btn} onClick={gen}>Скачать Word</button>
            }
        </div>
    );
};

export default FullResult;