import React, {useState} from 'react';
import style from './create-user.module.scss';
import Modal from "../../modal/modal";
import {SubmitHandler, useForm} from "react-hook-form";
import {useCreateUserMutation} from "../../../services/userService";
import {IDepartments} from "../../../types/departments";
import 'react-toastify/dist/ReactToastify.css';
import {successToast} from "../../../utils/toastify";
import { BsQuestionOctagonFill } from "react-icons/bs";

interface Props {
    active: boolean,
    close: () => void,
    departments: IDepartments[] | undefined
}

interface Form {
    fio: string,
    vacancy_name: string,
    department_id: number,
    tags: string,
    description: string
}

const CreateUser = ({active, close, departments}: Props) => {
    const {register, handleSubmit, formState: {errors}, reset} = useForm<Form>();
    const [createUser, {isSuccess}] = useCreateUserMutation();
    const [hashcode, setHashCode] = useState('');

    const handleCreateUser: SubmitHandler<Form> = async data => {
        const arrTags = data.tags.toLowerCase().split(' ');
        // @ts-ignore
        data.tags = arrTags;
        const result = await createUser(data).unwrap();
        setHashCode(result.hashcode);
        successToast('Пользователь создан');
    };

    const copyToClipboard = (code: string, e: any) => {
        e.preventDefault()
        navigator.clipboard.writeText(code);
        successToast('Код скопирован');
        setHashCode('');
        reset();
        close();
    }

    return (
        <Modal active={active} title={'Создание пользователя'} close={close}>
            <form className={style.create}>
                <div className={style.create__item}>
                    <div className={style.create__label}>
                        <span>ФИО</span>
                    </div>
                    <input type="text" className={style.create__input}
                           disabled={!!hashcode}
                           placeholder="Иванов Иван Иванович" {...register('fio', {required: true})}/>
                    {errors.fio && <span className={style.create__error}>Введите ФИО</span>}
                </div>
                <div className={style.create__item}>
                    <div className={style.create__label}>
                        <span>Вакансия</span>
                    </div>
                    {errors.vacancy_name && <span className={style.create__error}>Введите название вакансии</span>}
                    <input disabled={!!hashcode} type="text" className={style.create__input}
                           placeholder="1С-разработчик" {...register('vacancy_name', {required: true})}/>
                </div>
                <div className={style.create__item}>
                    <div className={style.create__label}>
                        <span>Отдел</span>
                    </div>
                    {errors.department_id && <span className={style.create__error}>Выберите отдел</span>}
                    <select className={style.create__input}
                            placeholder="Бухгалтерия" {...register('department_id', {required: true})}
                            disabled={!!hashcode}
                            defaultValue={''}
                    >
                        <option value="" disabled>Отдел не выбран</option>
                        {
                            departments?.map(item =>
                                <option key={item.id} value={item.id} className={style.create__option}>
                                    {item.name}
                                </option>
                            )
                        }
                    </select>
                </div>
                <div className={style.create__item}>
                    <div className={style.create__label}>
                        <span>Теги</span>
                        <BsQuestionOctagonFill title={'Перечислите через пробел ключевые слова, относящиеся к кандидату.'}/>
                    </div>
                    <input disabled={!!hashcode} type="text" className={style.create__input}
                           placeholder="Администратор разработчик react" {...register('tags')}/>
                </div>
                <div className={style.create__item}>
                    <div className={style.create__label}>
                        <span>Краткое описание</span>
                    </div>
                    <input disabled={!!hashcode} type="text" className={style.create__input}
                           placeholder="Опыт работы - 4 года" {...register('description')}/>
                </div>
                {
                    !hashcode
                        ?
                        <button disabled={!!hashcode} className={style.btn}
                                onClick={handleSubmit(handleCreateUser)}>Подтвердить</button>
                        :
                        <button className={style.btn} onClick={(e) => copyToClipboard(hashcode, e)}>{hashcode}</button>
                }
            </form>
        </Modal>
    );
};

export default CreateUser;