export function formatDateString(inputDateString: string): string {
    const options = { year: 'numeric', month: 'long', day: 'numeric'};

    const inputDate = new Date(inputDateString);
    //@ts-ignore
    const formatter = new Intl.DateTimeFormat('ru-RU', options);
    const formattedDate = formatter.format(inputDate);

    return formattedDate;
}

export function formatDateWithTime(inputDateString: string): string {
    const options = {month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric'};

    const inputDate = new Date(inputDateString);
    //@ts-ignore
    const formatter = new Intl.DateTimeFormat('ru-RU', options);
    const formattedDate = formatter.format(inputDate);

    return formattedDate;
}



export const getInitials = (str: string) => {
    const arr =  str.split(' ');
    if (arr.length >= 2) {
        const firstInitial = arr[0].slice(0, 1).toUpperCase();
        const secondInitial = arr[1].slice(0, 1).toUpperCase();
        return firstInitial + secondInitial;
    } else {
        return arr[0].slice(0, 1).toUpperCase();
    }
}
