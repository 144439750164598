import React, {useRef} from 'react';
import {IFormResult} from "../../../types/results";
import style from './form-result.module.scss';
import {saveAs} from 'file-saver';

import {asBlob} from 'html-docx-js-typescript'

interface Props {
    formResult: IFormResult | undefined
}

const FormResult = ({formResult}: Props) => {
    const resRef = useRef<HTMLDivElement>(null);
    const gen = () => {
        const html = resRef.current!.innerHTML
        asBlob(html).then(data => {
            // @ts-ignore
            saveAs(data, `Анкета - ${formResult?.user_name}.docx`)
        })
    }

    return (
        <>
            {
                formResult?.completion_time ?
                    <div className={style.result}>
                        <div ref={resRef}>
                            <div className={style.header}>
                                <h2>Анкета - {formResult.user_name}</h2>
                                <span>Потратил времени: {formResult?.completion_time}</span>
                            </div>
                            <div className={style.answers}>
                                {
                                    formResult?.answers_set.map((item, index) =>
                                        <div className={style.answer} key={item.question}>
                                            <div className={style.answer__top}>
                                                <strong>{index + 1}.</strong> {item.question}
                                            </div>
                                            <div className={style.answer__bottom}>- {item.answer}</div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                        <button className={style.btn} onClick={gen}>Скачать Word</button>
                    </div>
                    :
                    <span className={style.empty}>Пользователь ещё не заполнил анкету</span>
            }
        </>
    );
};

export default FormResult;