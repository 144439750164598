import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {baseUrl} from "../constants/constants";
import {ILogs} from "../types/logs";
import queryString from "query-string";
import {baseQueryWithReAuth} from "./inerceptor";


export const logsApi = createApi({
    reducerPath: 'logsApi',
    baseQuery: baseQueryWithReAuth,
    endpoints: (builder) => ({
        getLogs: builder.query<ILogs[], {
            page: number,
            fio: string,
            log_type: string[] | null
        }>({
            // @ts-ignore
            query: ({page, fio, log_type}) => ({
                url: 'admins/logs',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                },
                params: queryString.stringify({
                    page,
                    fio,
                    log_type,
                    page_size: 100,
                }, {skipNull: true})
            })
        })
    })
});

export const {useGetLogsQuery} = logsApi;
