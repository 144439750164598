import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {baseUrl} from "../constants/constants";
import {IVacancies} from "../types/vacancies";
import {baseQueryWithReAuth} from "./inerceptor";


export const vacanciesApi = createApi({
    reducerPath: 'vacanciesApi',
    baseQuery: baseQueryWithReAuth,
    endpoints: (builder) => ({
        getVacancies: builder.query<IVacancies[], {}>({
            query: () => ({
                url: 'vacancies',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                },
            })
        })
    })
})

export const {useGetVacanciesQuery} = vacanciesApi;