import React from 'react';
import style from '../create-user/create-user.module.scss';
import Modal from "../../modal/modal";
import {SubmitHandler, useForm} from "react-hook-form";
import {useUpdateUserMutation} from "../../../services/userService";
import {IDepartments} from "../../../types/departments";
import 'react-toastify/dist/ReactToastify.css';
import {successToast} from "../../../utils/toastify";
import {IUser} from "../../../types/user";
import {BsQuestionOctagonFill} from "react-icons/bs";

interface Props {
    active: boolean,
    close: () => void,
    departments: IDepartments[] | undefined,
    user: IUser
}

export interface UpdateForm {
    fio: string,
    vacancy_name: string,
    department_id: number,
    tags: string,
    description: string
}

const UpdateUser = ({active, close, departments, user}: Props) => {
   const form = useForm<UpdateForm>({
        defaultValues: {
            fio: user.fio,
            vacancy_name: user.vacancy_name,
            department_id: user.department_info ? +user.department_info.id : 1,
            tags: user.tags.join(' '),
            description: user.description
        }
    });
    const {register, handleSubmit, formState: {errors}, reset} = form;
    const [updateUser] = useUpdateUserMutation();



    const handleCreateUser: SubmitHandler<UpdateForm> = async data => {
        const arrTags = data.tags.split(' ');
        // @ts-ignore
        data.tags = arrTags;
        const id = user.id;
        const result = await updateUser({id, ...data}).unwrap();
        successToast('Информация обновлена');
    };



    return (
        <Modal active={active} title={'Изменить информацию'} close={close}>
            <form className={style.create}>
                <div className={style.create__item}>
                    <div className={style.create__label}>
                        <span>ФИО</span>
                    </div>
                    <input type="text"
                           className={style.create__input}
                           placeholder="Иванов Иван Иванович"
                           {...register('fio', {required: true})}
                    />
                    {errors.fio && <span className={style.create__error}>Введите ФИО</span>}
                </div>
                <div className={style.create__item}>
                    <div className={style.create__label}>
                        <span>Вакансия</span>
                    </div>
                    <input type="text"
                           className={style.create__input}
                           placeholder="1С-разработчик"
                           {...register('vacancy_name')}
                    />
                </div>
                <div className={style.create__item}>
                    <div className={style.create__label}>
                        <span>Отдел</span>
                    </div>
                    {errors.department_id && <span className={style.create__error}>Выберите отдел</span>}
                    <select className={style.create__input}
                            placeholder="Бухгалтерия" {...register('department_id', {required: true})}
                    >
                        {
                            departments?.map(item =>
                                <option key={item.id} value={item.id} className={style.create__option}>
                                    {item.name}
                                </option>
                            )
                        }
                    </select>
                </div>
                <div className={style.create__item}>
                    <div className={style.create__label}>
                        <span>Теги</span>
                        <BsQuestionOctagonFill title={'Перечислите через пробел ключевые слова, относящиеся к кандидату.'}/>
                    </div>
                    <input type="text"
                           className={style.create__input}
                           placeholder="Администратор разработчик react"
                           {...register('tags')}
                    />
                </div>
                <div className={style.create__item}>
                    <div className={style.create__label}>
                        <span>Краткое описание</span>
                    </div>
                    <input type="text"
                           className={style.create__input}
                           placeholder="Опыт работы - 4 года"
                           {...register('description')}
                    />
                </div>

                        <button className={style.btn}
                                onClick={handleSubmit(handleCreateUser)}>Изменить</button>
            </form>
        </Modal>
    );
};

export default UpdateUser;