import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {baseUrl} from "../constants/constants";
import {IAdizesResult, IBigFiveResults, IFormResult, IGerchikovResult} from "../types/results";
import {IShortResult} from "../types/shortResult";
import {baseQueryWithReAuth} from "./inerceptor";


export const resultsApi = createApi({
    reducerPath: 'resultsApi',
    baseQuery: baseQueryWithReAuth,
    endpoints: (builder) => ({
        getFormResult: builder.query<IFormResult, {}>({
            query: (id) => ({
                url: `users/${id}/security_form_results`,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                },
            })
        }),
        getAdizesResult: builder.query<IAdizesResult, {}>({
            query: (id) => ({
                url: `users/${id}/adizes_test_results`,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                },
            })
        }),
        getBigFiveResult: builder.query<IBigFiveResults, {}>({
            query: (id) => ({
                url: `users/${id}/big_five_test_results`,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                },
            })
        }),
        getGerchikovResult: builder.query<IGerchikovResult, {}>({
            query: (id) => ({
                url: `users/${id}/gerchikov_test_results`,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                },
            })
        }),
        getShortResult: builder.query<IShortResult, {}>({
            query: (id) => ({
                url: `users/${id}/full_tests_results?with_answers=false`,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                },
            })
        })
    })
})

export const {useGetFormResultQuery, useGetAdizesResultQuery, useGetBigFiveResultQuery, useGetGerchikovResultQuery, useGetShortResultQuery} = resultsApi;